<template>
  <StoryblokComponent
    v-if="story"
    :blok="story.content"
    :date="story.first_published_at || story.created_at"
    :name="story.name"
    :slug="story.full_slug"
    :partialslug="story.slug"
  />
</template>

<script lang="ts" setup>
import { useStoriesStore } from '@/stores/Stories';

const route = useRoute();
const router = useRouter();

let slug = 'home';

if (route.params.slug !== '') {
  if (Array.isArray(route.params.slug)) {
    slug = route.params.slug.join('/');
  } else {
    slug = route.params.slug;
  }
}

router.afterEach((to, from) => {
  if (!window.trackNav) {
    window.trackNav = { to: '', from: '' };
  }
  window.trackNav.to = to.fullPath;
  window.trackNav.from = from.fullPath;
  window.trackNav.time = new Date().toISOString();
  localStorage.setItem('previousPage', from.fullPath);
  localStorage.setItem('time', window.trackNav.time);
});

const store = useStoriesStore();
await store.fetchData();
const story = ref();
story.value = store.getStoryByFullSlug(slug);

onMounted(() => {
  if (story.value) {
    useStoryblokBridge(story.value.id, (evStory) => (story.value = evStory));
  }
});
</script>
